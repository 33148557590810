import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { Box, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { postAdminFilter } from '@services/postservice'

import WarningNote from '@objects/warningnote'
import LoadingSkeleton from '@objects/loadingSkeleton'
import PostDataTable from '@components/postdataTable'
import useSnackbar from '@hooks/useSnackbar'

export const frontmatter = {
  title: 'Neue Antworten',
}

function MarkedMagazineAnswersOverviewPage() {
  const intl = useIntl()
  const { toggleSnackbar } = useSnackbar()
  const AmountPerLoad = 500

  const isMountedRef = useRef(null)
  const [postList, setPostList] = useState([])
  const [currentAmount, setCurrentAmount] = useState(AmountPerLoad)
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)

  useEffect(() => {
    isMountedRef.current = true
    getPosts()
    const interval = setInterval(() => {
      if (isMountedRef.current) getPosts()
    }, 60000)
    return () => {
      isMountedRef.current = false
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    getPosts()
  }, [currentAmount])

  function getPosts(offset = 0) {
    if (!isMountedRef.current) return
    setLoading(true)
    postAdminFilter(offset, currentAmount, false, true, true).then((data) => {
      if (!data.apiError) {
        if (isMountedRef.current) {
          const dataArray = data.items
          setHasMore(data.hasMoreResults)
          setPostList(dataArray)
          setLoading(false)
        }
      } else {
        toggleSnackbar(
          `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
          'error'
        )
      }
    })
  }

  function loadMore() {
    setCurrentAmount(currentAmount + AmountPerLoad)
  }

  return (
    <>
      <WarningNote
        text={intl.formatMessage({
          id: 'markedarticles.introtext',
        })}
      />
      <LoadingSkeleton loading={loading} type="table">
        <PostDataTable postData={postList} onActionClick={getPosts} />
      </LoadingSkeleton>
      {hasMore && (
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={loadMore}
          >
            <FormattedMessage id="button.loadmore" />
          </Button>
        </Box>
      )}
    </>
  )
}

MarkedMagazineAnswersOverviewPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default MarkedMagazineAnswersOverviewPage
